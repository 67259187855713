.app-image-container {
  height: 30vh;
  min-height: 310px;
  width: 250px;
  background-position: center 0px;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 12px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.app-image-container .image-content {
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -150px 64px -128px rgb(0 0 0 / 80%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.app-image-container .app-image-element {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  object-position: center 0px;
}

.app-image-container .left-bottom-details {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  color: white;
  padding: 8px;
  text-align: left;
}

.app-image-container .right-bottom-details {
  text-align: right;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  color: white;
  padding: 8px;
}

.app-image-container .image-content-top .left-details {
  text-align: left;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  color: white;
  padding: 8px;
}

.app-image-container .image-content-top .right-details {
  text-align: right;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  color: white;
  padding: 8px;
}

.app-image-container .image-content-bottom .left-details {
  text-align: left;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  color: white;
  padding: 8px;
}

.app-image-container .image-content-bottom .right-details {
  text-align: right;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  color: white;
  padding: 8px;
}

@media (orientation: portrait) {
  .app-image-container {
    min-height: 360px;
  }
}

@media all and (min-device-width: 1024px) and (min-device-height: 768px) and (max-device-width: 2299px) and (orientation: landscape) {
  .app-image-container {
    min-height: 360px;
  }
}
