.navigation-tabs span[aria-selected="true"] {
  color: white !important;
  background-color: var(--app-theme) !important;
  font-weight: bold !important;
}

.navigation-tabs span {
  color: var(--app-theme) !important;
  font-weight: bold !important;
  margin: 4px !important;
}

.navigation-tabs div[role="tablist"] {
  margin-right: 0 !important;
}

.navigation-tabs span:hover {
  color: white !important;
  background-color: var(--app-theme) !important;
  font-weight: bold !important;
}

.navigation-tabs {
}

/* Portrait orientation */
@media screen and (orientation: portrait) {
  .navigation-tabs {
    text-align: center;
  }
}
