/* Portrait orientation */
@media screen and (orientation: portrait) {
  .img {
    box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin: 4px;
    width: 100%;
  }
}
/* Landscape orientation */
@media screen and (orientation: landscape) {
  .img {
    box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin: 4px;
    width: 32%;
  }
}
