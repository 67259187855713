.text {
    color: var(--app-theme);
    width: 100%;
}

.text-center {
    text-align: center;
}

.text-bold {
    font-weight: bold;
}