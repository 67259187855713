.app-entity {
  border-radius: 10px;
  position: relative;
  align-self: start;
  cursor: pointer;
}
.app-image-container {
  height: 30vh;
  min-height: 310px;
  width: 270px;
  background-position: center 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 599px) {
  .app-entity {
    width: 80%;
    height: 50vh;
  }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
  .app-entity {
    width: 60%;
    height: 50vh;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .app-entity {
    width: 40%;
    height: 40vh;
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  .app-entity {
    width: 20%;
    height: 24vh;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  .app-entity {
    width: 15%;
    height: 24vh;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1600px) {
  .app-entity {
    width: 14%;
    height: 32vh;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 2000px) {
  .app-entity {
    width: 14%;
    height: 32vh;
  }
}
