/* Portrait orientation */
@media screen and (orientation: portrait) {
  .thumbnail {
    object-fit: cover;
    object-position: 50% 0;
    padding: 2px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 36px;
    width: 36px;
  }

  .thumbnail-header {
    flex-basis: 64px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
  }

  .row {
    height: 48px;
  }
}

/* Landscape orientation */
@media screen and (orientation: landscape) {
  .thumbnail {
    object-fit: cover;
    object-position: 50% 0;
    padding: 2px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 96px;
    justify-content: center;
    width: 96px;
  }

  .thumbnail-header {
    flex-basis: 150px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
  }

  .row {
    height: 128px;
  }
}

/* Portrait orientation */
@media screen and (orientation: portrait) {
  .entity {
    height: 256px;
    width: 170px;
  }
}

/* Landscape orientation */
@media screen and (orientation: landscape) {
  .entity {
    height: 360px;
    width: 256px;
  }
}

.badge strong {
  font-size: 0.5rem !important;
}

.filter-radio {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--app-theme);
  font-weight: 600;
  margin: 0 8px 0 8px;
}

.filter-radio label {
  margin-left: 4px;
  margin-right: 4px;
}

.filter-radio span {
  font-weight: 600;

  color: var(--app-theme);
}

.kebab-button {
  all: unset;
  cursor: pointer;
  color: transparent !important;
  width: 12px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  outline: 0 !important;
}

.kebab-button:focus {
  all: unset;
  cursor: pointer;
  color: transparent !important;
  width: 12px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  outline: 0 !important;
}

.kebab-button:hover {
  all: unset;
  cursor: pointer;
  color: transparent !important;
  width: 12px !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  outline: 0 !important;
}

.pagination {
  
}

.pagination button {
  color: var(--app-theme) !important;
}

.pagination button:hover {
  color: white !important;
  background-color: var(--app-theme) !important;
}

.pagination svg {
  fill: var(--app-theme) !important;
}

.pagination span {
  color: var(--app-theme) !important;
}

.pagination button[aria-current="true"] {
  color: white !important;
  background-color: var(--app-theme) !important;
}
