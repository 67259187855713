.app-tag {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-top: 2px;
  cursor: pointer;
}

.app-tag .tag {
  font-weight: bold;
  color: var(--app-theme);
  border: solid var(--app-theme) 2px;
  padding: 6px 16px 6px 16px;
  border-radius: 32px;
}
