.app-video-container {
  height: 30vh;
  min-height: 310px;
  width: 250px;
  border-radius: 10px;
}

@media (orientation: portrait) {
  .app-video-container {
    min-height: 360px;
  }
}

@media all and (min-device-width: 1024px) and (min-device-height: 768px) and (max-device-width: 2299px) and (orientation: landscape) {
  .app-video-container {
    min-height: 360px;
  }
}
